@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap');

$black: #000000;

* {
  margin: 0;
  padding: 0;
  // background-color: white;

  font-family: 'Assistant' !important;
  // color: $black;
  direction: rtl;
}
html,
body,
body > div,
.app {
  height: 100%;
  overflow: hidden;
}

#chat-bot-launcher-container {
  height: 80px;
}
